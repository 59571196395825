@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Baskervville|Permanent+Marker|Righteous&display=swap");
/*
font-family: 'Baskervville', serif;
font-family: 'Righteous', cursive; // or Fredoka One
font-family: 'Permanent Marker', cursive;
*/
/*
0-425px: Small Phone
450-600px: Phone;
600-900px: Tablet Portrait;
900-1200px: Tablet landscape;
1200-1800px: normal style;
1800px+: Big Desktop;
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  position: relative; }
  @media only screen and (max-width: 37.5em) {
    body {
      overflow-x: hidden; } }

::selection {
  background-color: #071a52;
  color: #fff; }

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

body {
  font-family: "Baskervville", serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  color: #333; }

p {
  font-size: 1.6rem; }
  p:not(:last-child) {
    margin-bottom: 3rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Righteous", cursive;
  font-weight: 400;
  display: block; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: 300;
    line-height: 1;
    color: #999; }

h1,
h2,
h3 {
  margin-top: 2rem;
  margin-bottom: 1rem; }
  h1 small,
  h2 small,
  h3 small {
    font-size: 65%; }

h1 {
  font-size: 6rem;
  text-transform: uppercase; }
  @media only screen and (max-width: 37.5em) {
    h1 {
      font-size: 3.5rem; } }

h2 {
  font-size: 3.5rem;
  display: block; }
  @media only screen and (max-width: 37.5em) {
    h2 {
      font-size: 3rem; } }

h3 {
  font-size: 2.3rem; }
  @media only screen and (max-width: 56.25em) {
    h3 {
      font-size: 2rem; } }
  @media only screen and (max-width: 37.5em) {
    h3 {
      font-size: 1.6rem; } }

blockquote {
  font-size: 2.2rem;
  font-style: italic;
  line-height: 2;
  width: 85%;
  margin-right: 0;
  margin-left: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-bottom: 2rem;
  padding-top: 1.7rem;
  text-align: right;
  color: #071a52;
  border-bottom: 2px solid #071a52;
  border-top: 2px solid #071a52; }

.header {
  color: #fff;
  backface-visibility: hidden; }
  .header--main {
    line-height: 0.8;
    font-size: 5.9rem;
    font-family: "Baskervville", serif;
    text-shadow: 0.2rem 0.2rem 0.2rem #071a52;
    text-transform: uppercase; }
    @media only screen and (max-width: 75em) {
      .header--main {
        font-size: 8.9rem; } }
    @media only screen and (max-width: 37.5em) {
      .header--main {
        font-size: 6rem; } }
    .header--main span {
      display: block;
      font-size: 2rem;
      letter-spacing: 1.3rem;
      font-weight: 700;
      line-height: 1;
      margin-right: -1rem; }
      @media only screen and (max-width: 75em) {
        .header--main span {
          font-size: 4rem; } }
      @media only screen and (max-width: 37.5em) {
        .header--main span {
          font-size: 2rem; } }
  .header--sub {
    color: #a7ff83;
    font-size: 1.63rem;
    letter-spacing: 0.21rem;
    line-height: 1;
    text-shadow: 0.2rem 0.2rem 0.2rem #071a52;
    text-transform: uppercase; }
    @media only screen and (max-width: 75em) {
      .header--sub {
        font-size: 2.65rem; } }
    @media only screen and (max-width: 37.5em) {
      .header--sub {
        font-size: 1.63rem; } }
  .header p {
    font-style: italic;
    font-size: 1.6rem;
    text-shadow: 0.5rem 0.5rem 0.5rem #000; }

li p:not(:last-child) {
  margin-bottom: 1rem; }

li ol,
li ul {
  margin-inline-start: 1.8rem; }

li li ul,
li li ol {
  margin-inline-start: 5rem; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.text-muted {
  color: #777; }
  .text-muted a {
    color: #777;
    text-decoration: underline; }

.text-primary {
  color: #071a52 !important; }
  .text-primary svg {
    fill: #071a52 !important; }

.text-secondary {
  color: #086972 !important; }
  .text-secondary svg {
    fill: #086972 !important; }

.text-tertiary {
  color: #17b978 !important; }
  .text-tertiary svg {
    fill: #17b978 !important; }

.text-accent {
  color: #a7ff83 !important; }
  .text-accent svg {
    fill: #a7ff83 !important; }

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.6rem 15rem;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25); }
  .alert--success {
    background-color: #20bf6b; }
  .alert--error {
    background-color: #eb4d4b; }

footer {
  width: 100%;
  height: auto;
  background-color: #000102;
  color: #777;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center; }
  @media only screen and (max-width: 56.25em) {
    footer {
      grid-template-columns: 1fr;
      grid-template-rows: auto; } }
  footer .center-footer {
    grid-column: 2 / 3;
    justify-self: center;
    font-size: 1.6rem; }
    @media only screen and (max-width: 56.25em) {
      footer .center-footer {
        grid-column: 1 / 2;
        grid-row: 1 / 2; } }
    footer .center-footer .stack-logos {
      display: flex;
      justify-content: center;
      align-items: center; }
      footer .center-footer .stack-logos svg {
        margin-left: 1rem;
        margin-right: 1rem; }
    footer .center-footer .subtext {
      color: #777;
      font-size: 1rem; }
  footer .logo--footer {
    grid-column: 1 / 2;
    justify-self: center; }
    @media only screen and (max-width: 56.25em) {
      footer .logo--footer {
        grid-row: 2 / 3; } }
    footer .logo--footer img {
      padding: 2rem;
      height: 20rem;
      width: 20rem; }
  footer .right-footer {
    grid-column: 3 / 4;
    justify-self: center;
    align-self: right;
    display: flex;
    justify-content: flex-end; }
    @media only screen and (max-width: 56.25em) {
      footer .right-footer {
        grid-column: 1 / 2;
        grid-row: 3 / 4; } }
    footer .right-footer svg {
      fill: #777;
      padding: 0.5rem; }

nav {
  background-color: #000;
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3.75fr 3.3fr;
  align-items: center;
  z-index: 100; }
  @media only screen and (max-width: 75em) {
    nav {
      grid-template-columns: 2fr 1fr 7.05fr; } }
  nav .logo {
    display: none;
    padding-left: 5rem;
    grid-column: 1 / 2;
    width: 100%; }
    nav .logo .logo__image {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: 29px;
      height: 29px; }
      nav .logo .logo__image img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
      nav .logo .logo__image:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
    @media only screen and (max-width: 75em) {
      nav .logo {
        display: inline-block; } }
  nav ul {
    grid-column: 2 / 3;
    justify-self: end;
    display: inline-block;
    list-style-type: none; }
    nav ul li {
      display: inline-block;
      padding: 0 2rem; }
      nav ul li a {
        font-size: 1.6rem;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff; }
        @media only screen and (max-width: 90.63em) {
          nav ul li a {
            font-size: 1.45rem; } }
    @media only screen and (max-width: 75em) {
      nav ul {
        display: none; } }
  nav .nav-buttons {
    grid-column: 3 / 4;
    justify-self: end; }
    @media only screen and (max-width: 37.5em) {
      nav .nav-buttons {
        display: none; } }
  nav .nav__cta svg {
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: -0.6rem;
    fill: white; }

nav.unstuck {
  position: relative; }

#secondNav {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  align-items: center;
  z-index: 100; }
  @media only screen and (max-width: 56.25em) {
    #secondNav {
      grid-template-columns: 1fr 1fr; } }
  #secondNav .logo {
    display: inline-block; }
    #secondNav .logo > a {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      text-decoration: none;
      color: currentColor; }
    #secondNav .logo__image {
      width: 100px;
      height: 100px;
      justify-self: right; }
    #secondNav .logo h2 {
      justify-self: left;
      color: #fff;
      font-size: 3.1rem;
      line-height: 1.1; }

.column1 {
  max-width: 600px; }

.column2 {
  column-count: 2;
  column-gap: 4rem;
  column-rule: 1px solid #071a52; }
  @media only screen and (max-width: 75em) {
    .column2 {
      column-count: 1; } }

button {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.25rem 1.5rem;
  display: inline-block;
  border-radius: 0rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  font-family: "Righteous", cursive;
  border: none;
  cursor: pointer;
  background-color: transparent; }
  button:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  button:active, button:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.25rem 1.5rem;
  display: inline-block;
  border-radius: 0rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  font-family: "Righteous", cursive;
  border: none;
  cursor: pointer; }
  @media only screen and (max-width: 28.125em) {
    .btn, .btn:link, .btn:visited {
      padding: 0.25rem 2rem; } }

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }

.btn:active, .btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn--accent {
  background-color: #a7ff83;
  color: #fff; }

.btn--primary {
  background-color: #071a52;
  color: #fff; }

.btn--secondary {
  background-color: #086972;
  color: #fff; }

.btn--tertiary {
  background-color: #17b978;
  color: #fff; }

.btn--white {
  background-color: #fff;
  color: #000102; }

.btn--transparent {
  background-color: none;
  color: #fff; }

.btn-text, .btn-text--white, .btn-text:link, .btn-text--white:link, .btn-text:visited, .btn-text--white:visited {
  font-size: 1.6rem;
  color: #071a52;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #071a52;
  padding-left: 0;
  transition: all 0.2s; }
  .btn-text svg, .btn-text--white svg, .btn-text:link svg, .btn-text--white:link svg, .btn-text:visited svg, .btn-text--white:visited svg {
    fill: #071a52; }

.btn-text:hover, .btn-text--white:hover {
  background-color: #071a52;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }
  .btn-text:hover svg, .btn-text--white:hover svg {
    fill: #fff; }

.btn-text:active, .btn-text--white:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.btn-text--white {
  color: #fff !important;
  border-bottom-color: #fff !important;
  text-shadow: 2px 2px 2px #000; }
  .btn-text--white svg {
    fill: #fff !important; }
  .btn-text--white:hover {
    background-color: #fff !important;
    color: #071a52 !important;
    text-shadow: none; }
    .btn-text--white:hover svg {
      fill: #071a52 !important; }

.btn-text--large {
  font-size: 2.6rem !important; }

.button, .button:link, .button:visited {
  cursor: pointer;
  display: inline-block;
  min-height: 1.4rem;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #eee;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Baskervville", serif;
  margin: 0 0.35rem 0 0;
  padding: 1.1rem 2.1rem;
  font-weight: 700;
  line-height: 1.4rem;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(0, 0, 0, 0.15) inset;
  font-size: 1.6rem;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease; }
  .button svg, .button:link svg, .button:visited svg {
    fill: rgba(0, 0, 0, 0.6);
    height: 1.6rem; }

.button:active {
  background-color: #c3c3c3;
  color: rgba(0, 0, 0, 0.9); }
  .button:active svg {
    fill: rgba(0, 0, 0, 0.9); }

.button:focus {
  background-color: #c3c3c3;
  color: rgba(0, 0, 0, 0.8); }
  .button:focus svg {
    fill: rgba(0, 0, 0, 0.8); }

.button:hover {
  background-color: #c3c3c3;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(0, 0, 0, 0.15) inset;
  color: rgba(0, 0, 0, 0.8); }
  .button:hover svg {
    fill: rgba(0, 0, 0, 0.8); }

.button.primary, .button.primary:link, .button.primary:visited {
  background-color: #071a52;
  color: #fff;
  text-shadow: none;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15) inset; }
  .button.primary svg, .button.primary:link svg, .button.primary:visited svg {
    fill: #fff; }

.button.primary:active, .button.primary:focus, .button.primary:hover {
  background-color: #000102; }

.button.tertiary, .button.tertiary:link, .button.tertiary:visited {
  background-color: #17b978;
  color: #fff;
  text-shadow: none;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15) inset; }
  .button.tertiary svg, .button.tertiary:link svg, .button.tertiary:visited svg {
    fill: #fff; }

.button.tertiary:active, .button.tertiary:focus, .button.tertiary:hover {
  background-color: #0d6c46; }

.button .huge {
  font-size: 2rem !important; }
  .button .huge svg {
    height: 2rem !important; }

.animated.button {
  position: relative;
  overflow: hidden;
  padding-right: 0 !important;
  vertical-align: middle;
  z-index: 1; }
  .animated.button .content {
    will-change: transform, opacity; }
  .animated.button .visible.content {
    position: relative;
    margin-right: 2.1rem;
    transition: right 0.3s ease 0s;
    left: auto;
    right: 0; }
  .animated.button .hidden.content {
    position: absolute;
    width: 100%;
    transition: right 0.3s ease 0s;
    top: 50%;
    left: auto;
    right: -100%;
    margin-top: -0.7rem; }
  .animated.button:hover .visible.content {
    left: auto;
    right: 200%; }
  .animated.button:hover .hidden.content {
    left: auto;
    right: 0;
    display: block; }
    .animated.button:hover .hidden.content svg {
      opacity: 0.85; }
  .animated.button.fade .visible.content {
    left: auto;
    right: auto;
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s ease, transform 0.3s ease; }
  .animated.button.fade .hidden.content {
    opacity: 0;
    left: 0;
    right: auto;
    transform: scale(1.5);
    transition: opacity 0.3s ease, transform 0.3s ease; }
  .animated.button.fade:hover .visible.content {
    left: auto;
    right: auto;
    opacity: 0;
    transform: scale(0.75); }
  .animated.button.fade:hover .hidden.content {
    left: 0;
    right: auto;
    opacity: 1;
    transform: scale(1);
    display: block; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  font-size: 1.6rem; }
  .list-group__item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    border: 1px solid rgba(14, 180, 195, 0.125); }
    .list-group__item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
    .list-group__item:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
  .list-group--flush {
    border-radius: 0; }
    .list-group--flush > .list-group__item {
      border-width: 0 0 1px; }
      .list-group--flush > .list-group__item:last-child {
        border-bottom-width: 0; }

.label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.225rem;
  background-color: #086972;
  border: 0 solid #021e21;
  border-radius: 4px;
  background-image: none;
  padding: 0.82rem 1.16rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  transition: background 0.1s ease;
  font-size: 1.4rem;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3); }
  .label svg {
    fill: #fff;
    display: inline-block;
    height: 1.5rem; }

.ribbon.label {
  position: relative;
  margin: 0;
  min-width: 50%;
  width: fit-content;
  border-radius: 0 4px 4px 0;
  left: -1.6rem;
  top: 1rem;
  margin-right: -14.4px;
  padding-left: calc(14px + 14.4px);
  padding-right: 14.4px; }
  .ribbon.label:after {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    background-color: transparent !important;
    border-style: solid;
    border-width: 0 14.4px 14.4px 0;
    border-color: transparent;
    border-right-color: inherit;
    width: 0;
    height: 0; }

.card {
  background-color: #fff;
  margin-bottom: 5rem;
  margin-top: 5rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  overflow: hidden;
  flex: 0 1 30rem;
  min-height: 25rem;
  border-radius: 5px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all .3s ease; }
  .card:hover {
    background-image: radial-gradient(#3fe7a4, #17b978);
    color: #fff; }
    .card:hover svg {
      fill: #fff; }

.card--container {
  display: flex; }
  .card--container a, .card--container a:link, .card--container a:visited {
    color: inherit;
    text-decoration: none; }

.card--product {
  background-color: #fff;
  margin: 2.5rem;
  height: 32rem;
  width: 32rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 16px rgba(119, 119, 119, 0.8); }
  .card--product__picture {
    height: 26rem;
    width: 32rem;
    position: relative;
    overflow: hidden;
    display: block; }
    .card--product__picture img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center; }
  .card--product__header {
    width: 32rem;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    padding: 1.6rem; }
    .card--product__header--title {
      display: block; }
      .card--product__header--title h3 {
        display: inline;
        padding: 0;
        margin: 0;
        font-size: 1.3rem; }
      .card--product__header--title h4 {
        display: inline;
        padding: 0;
        margin: 0;
        font-size: 1.3rem; }
    .card--product__header p {
      color: #999;
      font-size: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 8.5rem;
      white-space: nowrap; }
  .card--product__footer {
    text-align: center; }
    .card--product__footer button {
      border: 1px solid #086972;
      color: #086972;
      margin-bottom: 2rem; }

.card--partner {
  background-color: transparent;
  margin: 1.5rem;
  width: 15rem;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .card--partner__picture {
    height: auto;
    width: 15rem;
    position: relative;
    overflow: hidden;
    display: block; }
    .card--partner__picture img {
      width: 100%;
      height: auto; }
  .card--partner__header {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.6rem;
    padding-top: .2rem;
    justify-content: center; }
    .card--partner__header h3 {
      font-size: 2rem;
      color: #000;
      text-decoration: none; }

.card__header {
  align-self: center;
  margin: 0 2rem;
  text-align: center; }

.card__picture {
  align-self: center;
  width: 10rem;
  height: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .card__picture svg {
    fill: #333;
    flex: 0 1 8rem;
    height: 8rem;
    width: 8rem;
    justify-self: center;
    align-self: center; }
  .card__picture img {
    flex: 0 1 8rem;
    justify-self: center;
    align-self: center;
    height: 100%;
    max-height: 8rem;
    width: auto;
    max-width: 8rem; }

.card__description {
  margin: 0 auto 1.5rem;
  opacity: 0; }
  .card__description .btn {
    text-align: center;
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 2px;
    color: #fff;
    text-decoration: none; }

.card:hover .card__description {
  opacity: 1; }

.news__card {
  background-color: transparent;
  border-top: 1px solid #999;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  line-height: 1.9;
  margin: 0 0 8px;
  text-decoration-color: #000;
  text-size-adjust: 100%;
  vertical-align: baseline; }
  .news__card a {
    color: #086972;
    cursor: pointer;
    text-decoration-color: #086972;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto; }
  .news__card--wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%; }
    @media only screen and (max-width: 37.5em) {
      .news__card--wrapper {
        flex-direction: column; } }
    .news__card--wrapper a {
      position: relative;
      width: 30%;
      transition-delay: 0s, 0s, 0s;
      transition-duration: .1s, .1s, .1s;
      transition-property: color, background-color, fill;
      transition-timing-function: ease, ease, ease; }
      @media only screen and (max-width: 37.5em) {
        .news__card--wrapper a {
          width: 90%; } }
  .news__card--img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%;
    z-index: 2;
    color: #086972; }
    .news__card--img img {
      display: block;
      height: 100%;
      width: 100%;
      max-width: 100%;
      position: absolute;
      object-fit: cover;
      top: 0;
      z-index: 2;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: cover;
      border: 0px none #086972; }
  .news__card--content {
    flex-grow: 1;
    padding: 16px;
    width: 70%; }
    @media only screen and (max-width: 37.5em) {
      .news__card--content {
        width: 90%; } }
    .news__card--content ul.heading-labels {
      margin: 0 0 6px;
      width: calc(100% - 32px);
      font-size: 12.5px;
      font-weight: 400;
      letter-spacing: .25px;
      line-height: normal;
      text-transform: uppercase;
      padding: 0;
      display: inline; }
      .news__card--content ul.heading-labels li {
        display: inline-block;
        margin: 0 16px 0 0;
        max-width: 100%;
        text-align: left;
        color: #086972;
        border-left: 0px none #086972; }
        .news__card--content ul.heading-labels li a {
          text-transform: uppercase;
          color: #086972;
          display: block;
          white-space: nowrap; }
          .news__card--content ul.heading-labels li a span {
            display: inline-block; }
          .news__card--content ul.heading-labels li a:hover {
            color: #021e21; }
    .news__card--content h2.news__card--title {
      display: block;
      margin: 0 0 6px;
      padding: 0;
      font-family: "Righteous", cursive;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: .264px;
      line-height: 1.1;
      text-transform: none;
      color: #000; }
      .news__card--content h2.news__card--title a {
        color: currentColor; }
        .news__card--content h2.news__card--title a:hover {
          color: #086972; }
    .news__card--content p.news__card--description {
      display: block;
      margin: 0 0 12px;
      padding: 0;
      font-family: "Baskervville", serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 1.4;
      color: #777; }
    .news__card--content p.news__card--dateline {
      margin: 0;
      padding: 0;
      font-family: "Righteous", cursive;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .28px;
      line-height: normal;
      text-decoration-color: #333;
      color: #333; }

.hero-news__card {
  background-color: transparent;
  border-top: 1px solid #999;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  line-height: 1.9;
  margin: 0 0 8px;
  text-decoration-color: #000;
  text-size-adjust: 100%;
  vertical-align: baseline;
  width: 100%; }
  .hero-news__card a {
    color: #086972;
    cursor: pointer;
    text-decoration-color: #086972;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto; }
  .hero-news__card a.hero-news__card--img {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%; }
    .hero-news__card a.hero-news__card--img img {
      display: block;
      height: auto;
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0; }
  .hero-news__card .news__card--content {
    width: 95%; }
    .hero-news__card .news__card--content h2 {
      font-size: 40px; }

.secondary-news__card {
  display: flex;
  align-items: flex-start;
  flex-basis: 100%;
  margin: 0 0;
  padding: 16px 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid #999;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  line-height: 1.9;
  text-decoration-color: #000;
  text-size-adjust: 100%;
  vertical-align: baseline; }
  .secondary-news__card a {
    color: #086972;
    cursor: pointer;
    text-decoration-color: #086972;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto; }
  @media only screen and (max-width: 37.5em) {
    .secondary-news__card {
      flex-direction: column; } }
  .secondary-news__card a.secondary-news__card--img {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 50%; }
    @media only screen and (max-width: 37.5em) {
      .secondary-news__card a.secondary-news__card--img {
        width: 100%; } }
    .secondary-news__card a.secondary-news__card--img img {
      display: block;
      height: auto;
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0; }
  .secondary-news__card .news__card--content {
    padding-top: 0;
    margin-top: -16px; }

.tertiary-news__card {
  display: flex;
  align-items: flex-start;
  margin: 0 0;
  padding: 16px 0;
  width: 100%;
  background-color: transparent;
  border-top: 1px solid #999;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  line-height: 1.9;
  text-decoration-color: #000;
  text-size-adjust: 100%;
  vertical-align: baseline; }
  .tertiary-news__card a {
    color: #086972;
    cursor: pointer;
    text-decoration-color: #086972;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto; }
  @media only screen and (max-width: 37.5em) {
    .tertiary-news__card {
      flex-direction: column; } }
  .tertiary-news__card:first-child {
    border: none; }
  .tertiary-news__card a.tertiary-news__card--img--wrapper {
    position: relative;
    width: 30%;
    display: none;
    margin: 0;
    padding: 0; }
    @media only screen and (max-width: 56.25em) {
      .tertiary-news__card a.tertiary-news__card--img--wrapper {
        display: block;
        width: 50%; } }
    @media only screen and (max-width: 37.5em) {
      .tertiary-news__card a.tertiary-news__card--img--wrapper {
        width: 100%; } }
  .tertiary-news__card .tertiary-news__card--img {
    display: block;
    height: 0;
    margin: 0;
    padding: 0;
    padding-top: 75%;
    position: relative;
    width: 100%;
    z-index: 2; }
    .tertiary-news__card .tertiary-news__card--img img {
      display: block;
      height: 100%;
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0px;
      z-index: 2; }
  .tertiary-news__card .news__card--content {
    padding-top: 0;
    margin-top: -16px; }
  .tertiary-news__card p.news__card--description {
    display: none; }
    @media only screen and (max-width: 56.25em) {
      .tertiary-news__card p.news__card--description {
        display: block; } }

.pricecard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-size: 1.6rem; }
  .pricecard__body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem; }
    .pricecard__body--title h2,
    .pricecard__body--title h3,
    .pricecard__body--title h4 {
      margin-top: 0;
      margin-bottom: 0; }
    .pricecard__body--title small {
      display: block;
      line-height: 1.7; }
    .pricecard__body:last-child {
      padding-bottom: 4rem; }
  .pricecard__footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .pricecard__footer:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  .pricecard > .list-group {
    border-top: transparent;
    border-bottom: transparent; }
    .pricecard > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .pricecard > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.price-group {
  display: flex;
  flex-flow: row wrap; }
  .price-group > .pricecard {
    flex: 1 0;
    margin-bottom: 0; }
    .price-group > .pricecard + .pricecard {
      margin-left: 0; }
  @media only screen and (max-width: 37.5em) {
    .price-group {
      flex-flow: column nowrap; }
      .price-group > .pricecard {
        flex: 0 1;
        margin-bottom: 15px; }
        .price-group > .pricecard + .pricecard {
          border-left: 1px solid rgba(0, 0, 0, 0.125); }
        .price-group > .pricecard:not(:first-child) {
          border-top-left-radius: calc(0.25rem - 1px);
          border-bottom-left-radius: calc(0.25rem - 1px); }
        .price-group > .pricecard:not(:last-child) {
          border-top-right-radius: calc(0.25rem - 1px);
          border-bottom-right-radius: calc(0.25rem - 1px); } }

.form__group:not(:last-child) {
  margin-bottom: 2rem; }

.form__input, .form__textarea {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 1.5rem 2rem;
  background-color: transparent;
  border-radius: 2px;
  border: none;
  border-bottom: 3px solid #fff;
  width: 90%;
  display: block; }
  @media only screen and (max-width: 75em) {
    .form__input, .form__textarea {
      padding: 1.5rem 1rem;
      width: 100%; } }
  .form__input:focus, .form__textarea:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid #a7ff83; }
  .form__input:focus:invalid, .form__textarea:focus:invalid {
    border-bottom: 3px solid #000102; }
  .form__input::-webkit-input-placeholder, .form__textarea::-webkit-input-placeholder {
    color: #999; }

.form__label span {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s; }

.form__input:placeholder-shown + span,
.form__textarea:placeholder-shown + span {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem); }

.form__textarea {
  min-height: 20rem;
  resize: none; }

input[type="text"]#headstrong {
  display: none; }

.checkgroup .form__input {
  display: inline-block;
  width: auto; }

.checkgroup .form__label span {
  display: inline;
  padding-left: 2rem; }
  .checkgroup .form__label span a {
    color: #fff; }
  @media only screen and (max-width: 75em) {
    .checkgroup .form__label span {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 56.25em) {
    .checkgroup .form__label span {
      font-size: 2rem; } }

.switch {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle; }
  .switch--root {
    width: 42px;
    height: 26px;
    margin: 8px;
    padding: 0;
    display: inline-flex;
    z-index: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle; }
  .switch--button-base {
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    flex: 0 0 auto;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 50%;
    top: 0;
    left: 0;
    color: #f7f7f7;
    z-index: 1;
    position: absolute;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 1px; }
    .switch--button-base.checked {
      color: #fff;
      transform: translateX(16px); }
      .switch--button-base.checked + .switch--track {
        border: none;
        opacity: 1;
        background-color: #0eb4c3; }
  .switch--button-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit; }
    .switch--button-label input[type="checkbox"],
    .switch--button-label input[type="radio"] {
      top: 0;
      left: -100%;
      width: 300%;
      cursor: inherit;
      height: 100%;
      margin: 0;
      opacity: 0;
      padding: 0;
      z-index: 1;
      position: absolute; }
  .switch--thumb {
    width: 24px;
    height: 24px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.7), 0px 1px 1px 0px rgba(0, 0, 0, 0.64), 0px 1px 3px 0px rgba(0, 0, 0, 0.62);
    border-radius: 50%;
    background-color: currentColor; }
  .switch--track {
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 1px solid #777;
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 13px;
    background-color: #f7f7f7; }
  .switch--label {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.15px;
    margin: 0; }

input[disabled] {
  display: none; }

.hidden {
  display: none; }

.modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s; }
  @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .modal {
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.3); } }
  .modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    background-color: #fff;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 5rem 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.5rem;
    overflow: hidden;
    opacity: 1;
    transition: all 0.5s 0.2s; }
    .modal__content p {
      margin-bottom: 1rem; }
    .modal__content ul {
      font-size: 1.6rem;
      font-style: "Baskervville", serif;
      color: #071a52;
      margin-left: 3rem; }
    @media only screen and (max-width: 75em) {
      .modal__content {
        padding: 5rem 2rem;
        grid-template-columns: 100%;
        grid-row-gap: 5rem; } }
    @media only screen and (max-width: 28.125em) {
      .modal__content {
        width: 85%; } }
  .modal__left {
    grid-column: 1 / 2; }
    @media only screen and (max-width: 75em) {
      .modal__left {
        grid-row: 2 / 3; } }
    .modal__left h2,
    .modal__left h3,
    .modal__left p {
      color: #071a52; }
  .modal__right {
    grid-column: 2 / 3;
    justify-self: flex-end; }
    @media only screen and (max-width: 75em) {
      .modal__right {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: center; } }
  .modal__close button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    color: #f7f7f7;
    text-decoration: none;
    display: inline-block;
    transition: all 0.2s;
    line-height: 1; }
    .modal__close button:hover {
      color: #0e33a2; }
    @media only screen and (max-width: 28.125em) {
      .modal__close button {
        top: 1.5rem;
        right: -0.5rem;
        font-size: 4rem; } }
  @media only screen and (max-width: 28.125em) {
    .modal .polaroid {
      width: 30.11rem;
      height: 31rem; }
      .modal .polaroid::before, .modal .polaroid::after {
        left: 10.25rem; }
      .modal .polaroid img {
        margin-left: 1.55rem;
        margin-right: 1.55rem;
        margin-top: 1.55rem;
        height: 19rem;
        width: 25rem; }
      .modal .polaroid__caption {
        margin-left: 1.55rem;
        margin-right: 1.55rem; }
        .modal .polaroid__caption p {
          font-size: 2rem; } }

.modal-card {
  cursor: pointer; }

.polaroid {
  width: 40.11rem;
  height: 41rem;
  background-color: #fff;
  box-shadow: 4px 5px 10px 1px rgba(0, 0, 0, 0.7);
  position: relative; }
  .polaroid img {
    height: 29rem !important;
    width: 35rem !important;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.83);
    margin-top: 2.55rem;
    margin-left: 2.55rem;
    margin-right: 2.55rem;
    overflow: hidden; }
  .polaroid__caption {
    margin-left: 2.55rem;
    margin-right: 2.55rem;
    padding-top: 1rem;
    text-align: center; }
    .polaroid__caption--text {
      font-family: "Permanent Marker", cursive;
      font-size: 2.7rem;
      color: #071a52; }
  .polaroid--taped::before, .polaroid--taped::after {
    content: "";
    display: block;
    position: absolute;
    left: 14.25rem;
    width: 11.5rem;
    height: 4.5rem;
    z-index: 2;
    background-color: rgba(243, 245, 228, 0.5);
    box-shadow: 1px 1.25px 2px #000; }
  .polaroid--taped::before {
    top: -2.5rem; }
  .polaroid--taped::after {
    bottom: -2.5rem; }
  .polaroid--taped.smallcaption .polaroid__caption {
    padding-top: 0.8rem; }
    .polaroid--taped.smallcaption .polaroid__caption--text {
      font-size: 2rem;
      line-height: 1.6; }

.polaroid-small {
  width: 20.055rem;
  height: 20.5rem;
  background-color: #fff;
  box-shadow: 2px 2.5px 5px 0.5px rgba(0, 0, 0, 0.7);
  position: relative; }
  .polaroid-small img {
    height: 14.5rem;
    width: 17.5rem;
    margin-top: 1.275rem;
    margin-left: 1.275rem;
    margin-right: 1.275rem;
    overflow: hidden; }
  .polaroid-small__caption {
    margin-left: 1.275rem;
    margin-right: 1.275rem;
    padding-top: 0.5rem; }
    .polaroid-small__caption--text {
      font-family: "Permanent Marker", cursive;
      font-size: 1.75rem;
      color: #071a52; }

.header {
  background-image: linear-gradient(90deg, rgba(7, 26, 82, 0) 0%, rgba(7, 26, 82, 0.83) 75%), url(/img/bg-hero.jpg);
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-rows: 30vh 30vh 30vh 30vh;
  grid-template-columns: 3.7fr 3fr 3.3fr;
  grid-gap: 0; }
  @media only screen and (max-width: 75em) {
    .header {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50vh 50vh; } }
  @media only screen and (max-width: 37.5em) {
    .header {
      grid-template-rows: 50vh 50vh; } }

.pitch {
  padding-right: 20rem;
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  align-self: start;
  justify-self: start; }
  @media only screen and (max-width: 90.63em) {
    .pitch {
      padding-right: 10rem; } }
  @media only screen and (max-width: 75em) {
    .pitch {
      display: none; } }

.headline {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-self: flex-end;
  justify-self: flex-end;
  text-align: right; }
  @media only screen and (max-width: 75em) {
    .headline {
      grid-column: 1 / 3;
      align-self: center;
      justify-self: center;
      text-align: center; } }

.branding {
  background-color: #000;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  align-self: center;
  justify-self: stretch; }
  @media only screen and (max-width: 75em) {
    .branding {
      grid-row: 2 / 3; } }
  @media only screen and (max-width: 37.5em) {
    .branding {
      grid-column: 1 / 3; } }
  .branding--imprint {
    margin-left: 6rem;
    margin-right: 2rem;
    align-self: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .branding--imprint_top, .branding--imprint_bottom {
      font-size: 3rem;
      letter-spacing: 0.2rem;
      overflow: auto; }
      .branding--imprint_top::before, .branding--imprint_top::after, .branding--imprint_bottom::before, .branding--imprint_bottom::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        border-bottom: 1px solid #fff; }
    .branding--imprint_top::before, .branding--imprint_top::after {
      width: 7.55rem; }
    .branding--imprint_bottom::before, .branding--imprint_bottom::after {
      width: 7.4rem; }
    .branding--imprint_co {
      text-transform: uppercase;
      font-size: 2.75rem;
      margin-top: 0;
      margin-bottom: 0; }
      @media only screen and (max-width: 28.125em) {
        .branding--imprint_co {
          font-size: 2.5rem; } }
      @media only screen and (min-width: 112.5em) {
        .branding--imprint_co {
          font-size: 3.25rem; } }
  .branding--logo {
    align-self: flex-end;
    flex-grow: 1; }
    .branding--logo img {
      height: 20rem;
      transform: rotate(-15deg); }

.side-border_top,
.side-border_bottom {
  content: "";
  width: 21.2rem;
  height: 1.7rem;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff; }

.side-border_top {
  margin-top: -2.24rem; }

.side-border_bottom {
  margin-bottom: -2.9rem; }
  @media only screen and (min-width: 112.5em) {
    .side-border_bottom {
      margin-bottom: -2.8rem; } }

#about-us {
  display: flex;
  flex-direction: row;
  padding: 10rem; }
  @media only screen and (max-width: 75em) {
    #about-us {
      padding: 10rem 2rem; } }
  @media only screen and (max-width: 68em) {
    #about-us {
      padding: 5rem 2rem;
      flex-direction: column-reverse; } }

.about-us {
  margin-left: 5rem; }
  @media only screen and (max-width: 90.63em) {
    .about-us {
      width: 60%; } }
  @media only screen and (max-width: 75em) {
    .about-us {
      width: auto; } }
  .about-us a {
    margin-top: 5rem !important; }
    .about-us a svg {
      height: 3rem;
      width: 3rem;
      margin-bottom: -.6rem; }

@media only screen and (max-width: 68em) {
  .about-us__headline {
    text-align: center; } }

.headshot {
  position: relative;
  align-self: center; }
  @media only screen and (max-width: 90.63em) {
    .headshot {
      align-self: flex-start; } }
  @media only screen and (max-width: 68em) {
    .headshot {
      align-self: center; } }
  .headshot .headshot__background {
    margin: 12rem;
    margin-left: 24rem;
    background-image: radial-gradient(#0e33a2, #071a52, #000102);
    height: 30rem;
    width: 30rem;
    transform: rotate(45deg); }
    @media only screen and (max-width: 90.63em) {
      .headshot .headshot__background {
        margin: 12rem;
        margin-top: 24rem; } }
    @media only screen and (max-width: 68em) {
      .headshot .headshot__background {
        margin: 12rem; } }
    .headshot .headshot__background .headshot__image {
      height: 30rem;
      transform: rotate(-45deg); }
      .headshot .headshot__background .headshot__image img {
        width: 100%;
        max-width: 30rem;
        height: auto; }

#services {
  padding: 10rem;
  background-image: linear-gradient(90deg, rgba(0, 1, 2, 0.83) 0%, rgba(0, 1, 2, 0.83) 100%), url(/img/bg-apps.jpg);
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 56.25em) {
    #services {
      padding: 5rem 2rem; } }

.services__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15rem 10rem;
  color: #fff;
  align-items: center; }
  @media only screen and (max-width: 56.25em) {
    .services__title {
      margin: 0 3rem 2rem; } }
  .services__title p {
    font-size: 2rem !important; }

.services__cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

#products,
#partners {
  padding: 10rem; }
  @media only screen and (max-width: 56.25em) {
    #products,
    #partners {
      padding: 5rem 2rem; } }

.products__title,
.partners__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15rem 10rem;
  color: #071a52;
  align-items: center; }
  @media only screen and (max-width: 56.25em) {
    .products__title,
    .partners__title {
      margin: 0 3rem 2rem; } }
  .products__title p,
  .partners__title p {
    font-size: 2rem !important; }

.products__cards,
.partners__cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

.partners__title {
  margin-bottom: 0; }

#contact {
  background-image: linear-gradient(90deg, rgba(7, 26, 82, 0.83) 0%, rgba(7, 26, 82, 0.83)), url(/img/bg-computer-desk.jpg);
  background-position: center;
  background-size: cover;
  padding: 10rem;
  color: #fff;
  text-shadow: 2px 2px 2px #000; }
  @media only screen and (max-width: 75em) {
    #contact {
      background-image: linear-gradient(90deg, rgba(7, 26, 82, 0.93) 0%, rgba(7, 26, 82, 0.93)), url(/img/bg-computer-desk.jpg); } }
  @media only screen and (max-width: 56.25em) {
    #contact {
      padding-left: 2rem;
      padding-right: 2rem; } }

.contact {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center; }
  @media only screen and (max-width: 75em) {
    .contact {
      grid-template-columns: 1fr; } }
  .contact__form {
    grid-column: 2 / 3;
    padding: 10rem; }
    @media only screen and (max-width: 75em) {
      .contact__form {
        grid-column: 1 / 2;
        padding: 1rem; } }

#portfolio {
  padding: 10rem;
  background-image: url(/img/bg-coffee.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed; }
  @media only screen and (max-width: 56.25em) {
    #portfolio {
      padding: 5rem 2rem; } }

.portfolio {
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto; }
  @media only screen and (max-width: 90.63em) {
    .portfolio {
      grid-template-columns: 4fr 1fr; } }
  @media only screen and (max-width: 75em) {
    .portfolio {
      grid-template-columns: 1fr; } }
  .portfolio__heading {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: center; }
  .portfolio__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    width: 100%;
    min-height: 10rem;
    grid-column: 1 / 2;
    grid-row: 2 / 3; }
  .portfolio__link {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    width: 71rem; }
    @media only screen and (max-width: 56.25em) {
      .portfolio__link {
        width: 58rem; } }
    @media only screen and (max-width: 37.5em) {
      .portfolio__link {
        width: 47rem; } }
    .portfolio__link svg {
      height: 3rem;
      width: 3rem;
      margin-bottom: -0.6rem; }

#portfolio-clean {
  padding-top: 10rem;
  padding-left: 10rem;
  padding-right: 10rem;
  width: 100%; }
  @media only screen and (max-width: 37.5em) {
    #portfolio-clean {
      padding-left: 1rem;
      padding-right: 1rem; } }
  #portfolio-clean .portfolio {
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: none;
    grid-template-rows: none; }
  #portfolio-clean h2,
  #portfolio-clean h3,
  #portfolio-clean .portfolio__pitch {
    color: #071a52; }
  #portfolio-clean h2,
  #portfolio-clean .portfolio__pitch {
    text-align: center; }
  #portfolio-clean h3 {
    text-shadow: none; }
  #portfolio-clean .portfolio__pitch {
    margin-bottom: 5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    font-size: 2.4rem; }

#process,
#benefits,
#snapshot {
  padding: 10rem; }
  @media only screen and (max-width: 75em) {
    #process,
    #benefits,
    #snapshot {
      padding: 10rem 2rem; } }

#studies .headline {
  width: 100%;
  height: auto;
  margin: 2rem 0; }

.studies {
  padding: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 75em) {
    .studies {
      padding: 10rem 2rem; } }
  @media only screen and (max-width: 56.25em) {
    .studies {
      flex-direction: column; } }
  @media only screen and (max-width: 56.25em) {
    .studies__desc {
      margin-bottom: 5rem; } }
  .studies__photo {
    justify-self: center;
    align-self: center;
    padding-left: 10rem;
    padding-right: 10rem;
    max-width: 65vw; }
    @media only screen and (max-width: 37.5em) {
      .studies__photo {
        max-width: 100vw;
        padding-left: 0;
        padding-right: 0; } }
    .studies__photo img {
      width: 100%;
      height: auto; }

.process,
.benefits,
.snapshot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly; }
  @media only screen and (max-width: 56.25em) {
    .process,
    .benefits,
    .snapshot {
      flex-direction: column; } }
  @media only screen and (max-width: 56.25em) {
    .process__desc,
    .benefits__desc,
    .snapshot__desc {
      margin-bottom: 5rem; } }
  .process__photo .process__ad,
  .benefits__photo .process__ad,
  .snapshot__photo .process__ad, .process__photo
  .benefits__ad,
  .benefits__photo
  .benefits__ad,
  .snapshot__photo
  .benefits__ad, .process__photo
  .snapshot__ad,
  .benefits__photo
  .snapshot__ad,
  .snapshot__photo
  .snapshot__ad {
    justify-self: center;
    align-self: center;
    padding-left: 10rem;
    padding-right: 10rem;
    max-width: 45vw; }

.benefits__desc {
  margin-right: 2rem; }
  .benefits__desc .list-group__item {
    border-top: none;
    border-left: none;
    border-right: none; }
  .benefits__desc ul ~ p {
    margin-top: 2rem; }

@media only screen and (max-width: 37.5em) {
  .benefits__ad iframe,
  .process__photo iframe {
    width: 300px;
    height: 169px; } }

.snapshot__photo {
  max-height: 200vh; }
  .snapshot__photo img {
    width: 40vw; }
    @media only screen and (max-width: 37.5em) {
      .snapshot__photo img {
        width: 100%; } }

.snapshot__desc {
  margin-left: 2rem; }
  .snapshot__desc .list-group__item {
    border-top: none;
    border-left: none;
    border-right: none; }

.testimonial {
  padding: 20rem;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(90deg, rgba(14, 180, 195, 0.83) 0%, rgba(14, 180, 195, 0.83) 100%), url(/img/bg-buildings.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed; }
  @media only screen and (max-width: 37.5em) {
    .testimonial {
      padding: 10rem 5rem; } }
  .testimonial p {
    font-size: 3rem; }
  .testimonial .citation {
    font-size: 2.7rem;
    padding-top: 3rem; }
    .testimonial .citation::before {
      content: "\2014";
      padding-right: 1.5rem; }

.portfolio__item {
  flex: 0 1 40rem;
  padding-bottom: 5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }
  .portfolio__item button {
    background-color: transparent;
    margin: 0; }
  @media only screen and (max-width: 28.125em) {
    .portfolio__item {
      padding-left: 0; } }

.slick-next {
  right: 14rem !important; }
  @media only screen and (max-width: 75em) {
    .slick-next {
      display: none; } }

.slick-prev {
  left: 14rem !important; }
  @media only screen and (max-width: 75em) {
    .slick-prev {
      display: none; } }

.slick-prev:before,
.slick-next:before {
  color: #071a52 !important;
  font-size: 6rem; }
  @media only screen and (max-width: 75em) {
    .slick-prev:before,
    .slick-next:before {
      content: ''; } }

.textwrap--right {
  float: right;
  margin-left: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 30rem;
  max-width: 50%; }
  @media only screen and (max-width: 37.5em) {
    .textwrap--right {
      float: left;
      clear: right;
      max-width: 100%;
      height: 50vh;
      margin-left: 0; } }

.textwrap--left {
  float: left;
  clear: right;
  margin-right: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 30rem;
  max-width: 50%; }
  @media only screen and (max-width: 37.5em) {
    .textwrap--left {
      max-width: 100%;
      height: 50vh;
      margin-right: 0; } }

.figure,
figure.image {
  width: calc((100% /14) * 12);
  margin: 2rem auto 2rem;
  overflow: hidden; }
  @media only screen and (max-width: 56.25em) {
    .figure,
    figure.image {
      width: calc((100% /14) * 12); } }
  @media only screen and (max-width: 37.5em) {
    .figure,
    figure.image {
      width: 100%; } }
  .figure img,
  .figure .figimage,
  figure.image img,
  figure.image .figimage {
    width: 100%;
    height: auto;
    max-height: 49.3rem; }
    @media only screen and (max-width: 37.5em) {
      .figure img,
      .figure .figimage,
      figure.image img,
      figure.image .figimage {
        max-height: 50vh; } }
  .figure figcaption,
  figure.image figcaption {
    font-size: 1rem;
    text-align: center;
    padding: 8px;
    color: #333; }

.responsive {
  width: 100%;
  max-width: 100%;
  height: auto !important; }

.modal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .modal {
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.3); } }
  .modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    background-color: #fff;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.25);
    transition: all .5s .2s; }
    @media only screen and (max-width: 37.5em) {
      .modal__content {
        width: 100%; } }
  .modal:target {
    opacity: 1;
    visibility: visible; }
  .modal:target .modal__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  .modal__close a:link, .modal__close a:visited {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    color: #f7f7f7;
    text-decoration: none;
    display: inline-block;
    transition: all .2s;
    line-height: 1; }
  .modal__close a:hover {
    color: #17b978; }

.viewModal {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transition: all .3s; }
  @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .viewModal {
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.3); } }
  .viewModal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: all .5s .2s; }
  .viewModal__close a:link, .viewModal__close a:visited {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    color: #f7f7f7;
    text-decoration: none;
    display: inline-block;
    transition: all .2s;
    line-height: 1; }
  .viewModal__close a:hover {
    color: #17b978; }

.exit-intent-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: #fff;
  transform: translateY(60%) scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .exit-intent-popup.visible {
    transform: translateY(0) scale(1); }
  .exit-intent-popup .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 4rem;
    color: #071a52;
    cursor: pointer; }
    .exit-intent-popup .close:hover {
      color: #a7ff83; }

.prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .prompt .logo {
    display: block;
    position: relative;
    margin: 1rem auto; }
    .prompt .logo .logo__image {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: 75px; }
      @media only screen and (max-width: 56.25em) {
        .prompt .logo .logo__image {
          height: 50px;
          width: 50px; } }
      .prompt .logo .logo__image img {
        width: 100%;
        height: auto; }
  .prompt input {
    background-color: #eee;
    width: 100%; }
    .prompt input.btn {
      background-color: #086972;
      color: #fff;
      padding-top: 2rem;
      padding-bottom: 2rem;
      font-size: 2rem; }

#topbar,
.topbar,
.scroll-prompt,
.end-prompt {
  z-index: 25;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0 2px 6px #17b978;
  text-align: center;
  margin: 0;
  vertical-align: baseline;
  font-size: 1.6rem;
  font: "Baskervville", serif; }
  #topbar .inner,
  .topbar .inner,
  .scroll-prompt .inner,
  .end-prompt .inner {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 120rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    #topbar .inner svg,
    .topbar .inner svg,
    .scroll-prompt .inner svg,
    .end-prompt .inner svg {
      display: block;
      flex-shrink: 0;
      margin-right: 2.5rem;
      fill: #17b978; }
      @media only screen and (max-width: 37.5em) {
        #topbar .inner svg,
        .topbar .inner svg,
        .scroll-prompt .inner svg,
        .end-prompt .inner svg {
          display: none; } }
    #topbar .inner__title,
    .topbar .inner__title,
    .scroll-prompt .inner__title,
    .end-prompt .inner__title {
      margin-right: 3rem;
      font-size: 2rem;
      line-height: 1.8;
      margin-bottom: 0; }
      @media only screen and (max-width: 56.25em) {
        #topbar .inner__title,
        .topbar .inner__title,
        .scroll-prompt .inner__title,
        .end-prompt .inner__title {
          font-size: 14px;
          line-height: 1.6667; } }
      @media only screen and (max-width: 37.5em) {
        #topbar .inner__title,
        .topbar .inner__title,
        .scroll-prompt .inner__title,
        .end-prompt .inner__title {
          margin-bottom: 10px; } }
    #topbar .inner__btn-wrap,
    .topbar .inner__btn-wrap,
    .scroll-prompt .inner__btn-wrap,
    .end-prompt .inner__btn-wrap {
      display: flex; }
      #topbar .inner__btn-wrap .btn-topbar,
      .topbar .inner__btn-wrap .btn-topbar,
      .scroll-prompt .inner__btn-wrap .btn-topbar,
      .end-prompt .inner__btn-wrap .btn-topbar {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.6667;
        font-weight: 700;
        letter-spacing: .126em;
        padding: 1.6rem 2rem 1.4rem;
        width: auto;
        min-width: 14rem;
        border: 1px solid;
        border-radius: 2px;
        white-space: normal;
        background-color: #fff;
        border-color: #17b978;
        color: #17b978;
        cursor: pointer; }
        @media only screen and (max-width: 37.5em) {
          #topbar .inner__btn-wrap .btn-topbar,
          .topbar .inner__btn-wrap .btn-topbar,
          .scroll-prompt .inner__btn-wrap .btn-topbar,
          .end-prompt .inner__btn-wrap .btn-topbar {
            font-size: 1rem;
            padding: 16px 10px 14px;
            width: 100%;
            min-width: none; } }
        #topbar .inner__btn-wrap .btn-topbar:hover,
        .topbar .inner__btn-wrap .btn-topbar:hover,
        .scroll-prompt .inner__btn-wrap .btn-topbar:hover,
        .end-prompt .inner__btn-wrap .btn-topbar:hover {
          background-color: #17b978;
          border-color: #17b978;
          color: #fff; }
        #topbar .inner__btn-wrap .btn-topbar:first-child,
        .topbar .inner__btn-wrap .btn-topbar:first-child,
        .scroll-prompt .inner__btn-wrap .btn-topbar:first-child,
        .end-prompt .inner__btn-wrap .btn-topbar:first-child {
          margin-right: 2.5rem; }
          @media only screen and (max-width: 37.5em) {
            #topbar .inner__btn-wrap .btn-topbar:first-child,
            .topbar .inner__btn-wrap .btn-topbar:first-child,
            .scroll-prompt .inner__btn-wrap .btn-topbar:first-child,
            .end-prompt .inner__btn-wrap .btn-topbar:first-child {
              margin-right: 5px; } }
        #topbar .inner__btn-wrap .btn-topbar:nth-child(2),
        .topbar .inner__btn-wrap .btn-topbar:nth-child(2),
        .scroll-prompt .inner__btn-wrap .btn-topbar:nth-child(2),
        .end-prompt .inner__btn-wrap .btn-topbar:nth-child(2) {
          margin-left: .5rem; }

.topbar,
.scroll-prompt,
.end-prompt {
  position: relative; }

.scroll-prompt {
  background: none;
  box-shadow: none;
  border-bottom: 2px solid #17b978;
  z-index: 1; }
  .scroll-prompt:hover svg {
    fill: #fff; }

.end-prompt {
  background: none;
  box-shadow: none;
  border: none;
  z-index: 1;
  color: #fff; }

.fixed-topbar {
  position: fixed;
  top: 0;
  left: 0; }

.signup-box {
  margin: 2rem auto 32px;
  max-width: 114rem;
  padding-top: 16px; }
  .signup-box.mobile {
    display: none; }
    @media only screen and (max-width: 56.25em) {
      .signup-box.mobile {
        display: block; } }
  .signup-box__main {
    background: #fff;
    border: 2px solid #086972;
    color: #000;
    padding: 32px 16px 16px;
    position: relative; }
  .signup-box__logo {
    background: #fff;
    left: calc(50%);
    position: absolute;
    top: 2px;
    padding: 0 16px;
    transform: translate(-50%, -50%);
    width: 80px; }
    .signup-box__logo img {
      width: 100%;
      height: auto; }
  .signup-box h2.signup-box__title {
    font-size: 28px;
    color: #086972;
    font-style: normal;
    letter-spacing: .336px;
    line-height: 1.1;
    text-align: center;
    text-transform: none;
    display: block;
    padding: 0;
    margin: 0 0 12px; }
  .signup-box__body {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-row-gap: 8px; }
    .signup-box__body label:nth-last-child(2) {
      grid-column-start: 1;
      grid-column-end: 2; }
  .signup-box--form__field {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 10px; }
  .signup-box--form__email span {
    display: inline;
    font-size: 15px;
    float: left; }
  .signup-box--form__input {
    grid-column-start: 1;
    grid-column-end: 2; }
    .signup-box--form__input input {
      width: 100%;
      border: 1px solid #086972;
      border-radius: 0;
      outline: none;
      padding: 8px;
      transition: background-color .2s;
      font-size: 15px;
      line-height: 1.9;
      text-align: start;
      word-spacing: 0px;
      color: #000;
      cursor: text; }
  .signup-box--disclaimer {
    margin: 0 auto;
    padding: 0 0 8px;
    font-family: "Baskervville", serif;
    font-size: 14px;
    line-height: 1.9;
    text-align: left;
    color: #777;
    grid-column-start: 1;
    grid-column-end: 3; }
    .signup-box--disclaimer a {
      text-decoration: none;
      color: #086972; }

button.signup-btn {
  align-items: flex-start;
  display: inline-block;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
  font-family: "Righteous", cursive;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1.35px;
  line-height: 1.9;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #086972;
  grid-column-start: 1;
  grid-column-end: 3; }

.index--services,
.index--products {
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  width: 100%;
  margin: 2rem 0; }
  .index--services h3,
  .index--products h3 {
    display: block;
    background-color: #eee;
    color: #000;
    font-size: 2rem;
    margin: 0;
    padding: 1rem;
    text-align: left;
    line-height: 1.9; }
  .index--services ul,
  .index--products ul {
    display: block;
    margin: 0;
    padding: 1rem;
    text-align: left;
    line-height: 1.9;
    font-size: 1.6rem;
    background-color: #f7f7f7; }
  .index--services li,
  .index--products li {
    display: block;
    border-bottom: 1px solid #eee; }
    .index--services li:last-child,
    .index--products li:last-child {
      border: none; }
    .index--services li a,
    .index--products li a {
      color: #071a52;
      text-decoration: none; }
      .index--services li a:hover,
      .index--products li a:hover {
        color: #17b978; }

main {
  background-image: linear-gradient(rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)), url(/img/bg-wireframe.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed; }

.privacypolicy,
.termsofservice {
  margin: 5rem 2.5rem;
  font-size: 1.6rem; }

.homepage-news {
  margin-top: 10rem;
  margin-bottom: 10rem; }
  .homepage-news h2 {
    padding-left: 20rem; }
  .homepage-news .news__hero--footer {
    padding-left: 20rem; }

.single-contact {
  width: 70%;
  margin: 0 auto; }
  @media only screen and (max-width: 56.25em) {
    .single-contact {
      width: 100%; } }

.landing {
  position: relative;
  background-image: linear-gradient(rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87)), url(/img/bg-wireframe.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed; }
  .landing .logo {
    background-color: transparent;
    position: fixed;
    z-index: 20;
    margin-top: 2rem;
    margin-left: 2rem; }
    .landing .logo .logo__image {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: 75px;
      height: 75px; }
      @media only screen and (max-width: 56.25em) {
        .landing .logo .logo__image {
          height: 50px;
          width: 50px; } }
      .landing .logo .logo__image img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
  .landing .header {
    display: grid;
    grid-template-rows: 10vh 90vh;
    grid-template-columns: 1fr 1fr;
    max-height: 100vh;
    min-height: 812px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(90deg, rgba(51, 51, 51, 0.9), rgba(51, 51, 51, 0.9)), url(/img/bg-branding-company-landing.jpg); }
    @media only screen and (max-width: 75em) {
      .landing .header {
        grid-template-columns: 1fr;
        grid-template-rows: 35% 65%;
        max-height: 200vh; } }
    @media only screen and (max-width: 37.5em) {
      .landing .header {
        max-height: 200vh;
        grid-template-rows: 45% 55%; } }
    .landing .header .hero--left {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      align-self: center;
      justify-self: center;
      text-align: center;
      padding-left: 10rem; }
      @media only screen and (max-width: 75em) {
        .landing .header .hero--left {
          grid-column: 1 / 3;
          grid-row: 1 / 2;
          padding-left: 0;
          width: 90%; } }
      @media only screen and (max-width: 56.25em) {
        .landing .header .hero--left {
          margin-top: 8rem; } }
    .landing .header .headline {
      text-align: left; }
      @media only screen and (max-width: 75em) {
        .landing .header .headline {
          text-align: center; } }
      .landing .header .headline .header--sub {
        font-size: 4.6rem;
        line-height: 1; }
        @media only screen and (max-width: 37.5em) {
          .landing .header .headline .header--sub {
            font-size: 3rem; } }
      .landing .header .headline .header--main {
        line-height: 1.2;
        font-size: 4rem; }
        @media only screen and (max-width: 75em) {
          .landing .header .headline .header--main {
            font-size: 3rem;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            font-weight: 700;
            letter-spacing: 1.3rem; } }
        @media only screen and (max-width: 37.5em) {
          .landing .header .headline .header--main {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            font-size: 2.25rem; } }
        .landing .header .headline .header--main span {
          font-size: 4.6rem;
          line-height: 1.2; }
          @media only screen and (max-width: 56.25em) {
            .landing .header .headline .header--main span {
              font-size: 3rem;
              display: inline; } }
          @media only screen and (max-width: 37.5em) {
            .landing .header .headline .header--main span {
              font-size: 2.25rem; } }
    @media only screen and (max-width: 28.125em) {
      .landing .header {
        padding: 0 2rem; } }
    .landing .header .hero--right {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      align-self: center;
      justify-self: center;
      text-align: center; }
      @media only screen and (max-width: 75em) {
        .landing .header .hero--right {
          padding-bottom: 10rem;
          width: 80%;
          grid-column: 1 / 3;
          grid-row: 2 / 3; } }
      .landing .header .hero--right p {
        font-size: 2rem; }

.list-group__item.checked svg,
.list-group__item.unchecked svg {
  height: 2rem;
  display: inline-block; }

.list-group__item.checked svg {
  fill: #3fe7a4; }

.list-group__item.unchecked {
  color: #999; }
  .list-group__item.unchecked svg {
    fill: #ff5757; }

#pricing {
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative; }
  @media only screen and (max-width: 37.5em) {
    #pricing {
      padding-bottom: 0; } }
  #pricing .price-group .pricecard:nth-of-type(2) {
    transform: translateY(-2vh);
    margin-left: 1rem;
    margin-right: 1rem; }
    @media only screen and (max-width: 37.5em) {
      #pricing .price-group .pricecard:nth-of-type(2) {
        margin-left: 0;
        margin-right: 0; } }
  @media only screen and (max-width: 37.5em) {
    #pricing .price-group {
      transform: translateY(-27vh); }
      #pricing .price-group .pricecard:nth-of-type(2) {
        transform: none; } }

.news {
  padding-top: 5rem; }
  .news__hero {
    overflow: hidden; }
    .news__hero--container {
      margin-bottom: 1.6rem; }
      .news__hero--container__inner {
        margin: 0 auto;
        max-width: 114rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center; }
        @media only screen and (max-width: 56.25em) {
          .news__hero--container__inner {
            flex-direction: column; } }
    .news__hero--main {
      flex-basis: 50%; }
    .news__hero--secondary {
      display: flex;
      flex-basis: 50%;
      flex-wrap: wrap;
      margin: 0;
      margin-left: 16px;
      padding: 0;
      color: #000;
      border-top: 1px solid #999; }
      @media only screen and (max-width: 56.25em) {
        .news__hero--secondary {
          margin-right: 16px; } }
    .news__hero--column {
      display: block;
      flex-basis: 50%;
      margin: 0;
      padding: 0; }
      @media only screen and (max-width: 56.25em) {
        .news__hero--column {
          flex-basis: 100%; } }
      .news__hero--column:nth-child(3) {
        padding-left: 16px;
        flex-basis: 0;
        flex-grow: 1; }
        @media only screen and (max-width: 56.25em) {
          .news__hero--column:nth-child(3) {
            display: none; } }
  .news__section--wrapper {
    margin: 5rem auto;
    width: 100%;
    max-width: 1140px; }
    @media only screen and (max-width: 56.25em) {
      .news__section--wrapper {
        margin-left: 16px;
        margin-right: 16px; } }
    .news__section--wrapper--inner {
      margin: 0 auto;
      width: 100%;
      max-width: 1100px; }
  .news__section--flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    position: relative; }
  .news__index {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0; }
  .news__sidebar {
    display: block;
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    margin-left: 31.2px;
    min-width: 0;
    padding: 0; }
    @media only screen and (max-width: 56.25em) {
      .news__sidebar {
        display: none; } }

.signup-mobile {
  display: none;
  padding: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .signup-mobile {
      display: block; } }

.post {
  font-family: "Baskervville", serif;
  background-color: #fff;
  color: #000;
  line-height: 1.9;
  vertical-align: baseline;
  font-size: 20px; }
  .post a, .post a:link, .post a:visited {
    color: #086972;
    text-decoration: none; }
  .post a:hover {
    text-decoration: underline;
    color: #071a52; }
  .post__wrapper {
    margin: 10rem auto 0;
    max-width: 1100px; }
  .post__inner {
    margin: 16px 0 0;
    padding: 16px 16px 0;
    position: relative; }
  .post--header {
    margin: 0 0 24px;
    padding: 0; }
    .post--header__tags {
      font-family: "Righteous", cursive;
      font-style: normal;
      font-weight: 400;
      letter-spacing: normal;
      line-height: normal;
      text-transform: uppercase;
      font-size: 13px; }
    .post--header__title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between; }
    .post--header__excerpt {
      font-weight: 400;
      letter-spacing: normal;
      line-height: normal;
      text-transform: normal;
      font-size: 20px;
      color: #777;
      margin-bottom: 12px;
      max-width: 798px; }
    .post--header__byline {
      font-family: "Righteous", cursive;
      font-weight: 400;
      letter-spacing: .28px;
      line-height: normal;
      font-size: 14px;
      color: #777; }
  .post--share {
    display: flex;
    flex-flow: row nowrap;
    letter-spacing: 1.8px;
    line-height: 0;
    list-style-type: none;
    padding-left: 0;
    position: relative; }
    .post--share h2 {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: .47px;
      line-height: 1.1;
      text-transform: none;
      margin-bottom: 12px; }
    .post--share ul.social-share {
      list-style-type: none;
      margin: 0;
      display: flex;
      font-size: 12.5px; }
      .post--share ul.social-share li:first-child {
        margin-left: -8px; }
      .post--share ul.social-share li + li {
        margin-left: 8px; }
    .post--share a.social-share--button {
      letter-spacing: 1.25px;
      line-height: 1;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      padding: 8px;
      color: #000;
      transition: opacity .3s; }
      .post--share a.social-share--button svg {
        fill: #071a52;
        cursor: pointer; }
      .post--share a.social-share--button:hover svg {
        fill: #0e33a2; }
  .post--layout {
    margin: 16px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0; }
    @media only screen and (max-width: 56.25em) {
      .post--layout {
        flex-direction: column; } }
    .post--layout__main {
      min-width: 0;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0; }
    .post--layout__sidebar {
      min-width: 0;
      flex-basis: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-left: 31.2px; }
      @media only screen and (max-width: 56.25em) {
        .post--layout__sidebar .index--services,
        .post--layout__sidebar .index--products {
          display: none; } }
  .post--content {
    font-family: "Baskervville", serif;
    font-weight: 400;
    margin: 0 0 24px;
    position: relative; }
    .post--content p {
      font-size: 20px;
      margin-bottom: 24px; }
      .post--content p:last-child::after {
        content: " ■";
        color: #086972; }
    .post--content ul, .post--content ol {
      margin-inline-start: 2rem; }
    .post--content aside {
      display: block; }
      .post--content aside .read-more {
        margin-bottom: 24px;
        border-color: #999;
        border-style: solid;
        border-width: 1px 0;
        padding: 8px 0;
        clear: right; }
        .post--content aside .read-more__intro {
          font-family: "Baskervville", serif;
          font-size: 15px;
          margin-bottom: 20px;
          text-transform: uppercase; }
        .post--content aside .read-more h4 {
          font-size: 20px;
          text-transform: none;
          letter-spacing: .24px;
          line-height: 1.1;
          margin-bottom: 12px; }
          .post--content aside .read-more h4 a {
            border-bottom: 0;
            color: #086972;
            text-decoration: none; }
  .post h1.post--title {
    font-family: "Righteous", cursive;
    font-style: normal;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: .585938px;
    line-height: 1.1;
    text-transform: none;
    margin-bottom: 12px;
    color: #000;
    padding-top: 12px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; }

ul.tags-list {
  display: inline;
  margin: 0;
  padding: 0; }
  ul.tags-list li.tag {
    color: #086972;
    font-family: "Righteous", cursive;
    display: inline-block;
    margin: 0 16px 0 0;
    position: relative;
    text-transform: uppercase;
    z-index: 0; }
    ul.tags-list li.tag a {
      color: currentColor;
      display: block;
      padding: 0px;
      white-space: nowrap; }
      ul.tags-list li.tag a span {
        display: inline-block; }

.hero--image {
  margin: 0 0 32px; }
  .hero--image__wrapper {
    display: block;
    position: relative; }
    .hero--image__wrapper--inner {
      display: block;
      margin-bottom: 0;
      width: 100%; }
      .hero--image__wrapper--inner img {
        border: 0;
        max-width: 100%;
        margin-bottom: 0;
        display: block;
        width: 100%;
        height: auto; }
  .hero--image__meta {
    color: #000;
    display: block;
    font-size: 15px;
    width: 100%;
    padding: 8px 0;
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid #999;
    font-family: "Baskervville", serif;
    font-weight: 400;
    letter-spacing: normal;
    line-height: normal;
    text-transform: none; }
    .hero--image__meta cite {
      display: inline;
      opacity: .75; }
      .hero--image__meta cite a {
        color: #086972;
        text-decoration: none; }

.nextup {
  display: block; }
  .nextup__related {
    margin-bottom: 48px; }
    .nextup__related--head {
      font-weight: 900;
      letter-spacing: normal;
      line-height: normal;
      text-transform: none;
      font-size: 22px;
      border-bottom: 3px solid #999;
      color: #000;
      margin: 0 0 8px 0;
      padding: 0 0 8px 0; }
      .nextup__related--head a {
        text-decoration: none;
        color: #086972; }
    .nextup__related ul {
      font-family: "Righteous", cursive;
      font-style: normal;
      font-weight: inherit;
      letter-spacing: normal;
      line-height: 1;
      text-transform: none;
      font-size: 20px;
      margin: 0;
      list-style: none; }
      .nextup__related ul li {
        padding: 8px 0;
        color: #086972;
        border-bottom: none; }
        .nextup__related ul li a {
          text-decoration: none;
          color: #086972; }
